<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {dateUtil} from "@/helpers/date-util";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";


/**
 * MarketPlace User Orders Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  components: {

    Layout,
    PageHeader
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      products: null

    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('marketplace-user-orders.title'),
          active: true
        }
      ]
    },

    async loadOrders() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/marketplace/order/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage,
          "forUser": true
        }
      });

      this.table.items = result.data.marketplaceOrders;
      this.table.totalRows = result.data.count;
      this.table.rows = result.data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      await this.loadProducts()
      return this.table.items;
    },

    async loadProducts() {
      const ids = [];
      for (let marketplaceOrder of this.table.items) {
        ids.push(marketplaceOrder.id);
      }

      const result = await axios.get(`/marketplace/order/products`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "orderIds": ids.join(","),
        }
      });

      this.products = new Map(result.data.map((obj) => [obj.marketplaceOrderId, obj]));
    },

    getProduct(marketplaceOrderId) {
      if (!this.products) {
        return null
      }

      return this.products.get(marketplaceOrderId) || null
    },

    getFields() {
      return [
        {
          key: 'createdAt', label: 'Utworzono', formatter: value => {
            if (!value) {
              return "-"
            }

            return dateUtil.asDateTime(value)
          }
        },
        {key: "product", slot: true, label: 'Produkt'},
        {
          key: "status", label: 'Status zamówienia', formatter: value => {
            return this.$t('marketplace.order.status.' + value)
          }
        },
        {key: "payment_status", slot: true, label: 'Status płatności'},
        {key: "action", slot: true, label: 'Akcje'}
      ]
    },

  },

  async created() {
    await this.loadOrders()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('marketplace-user-orders.title')" :items="getItems()"/>
    <template v-if="table.items">

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ecat-table
                  :table="table"
                  :fields="getFields()"
                  :items="loadOrders"


              >
                <template v-slot:payment_status="{ item }">
                  <span>{{ item.status === 'ORDER_COMPLETED' ? "Opłacone" : "Nieopłacone" }}</span>
                </template>

                <template v-slot:action="{ item }">
                  <b-button v-if="item.status !== 'ORDER_COMPLETED'" variant="primary"
                            :href="`/dashboard/marketplace/payment/${item.id}`">Zapłać
                  </b-button>
                </template>

                <template v-slot:product="{ item }">
                    <span :set="product = getProduct(item.id)">
                      {{ product ? product.title : "" }}
                    </span>
                </template>
              </ecat-table>

            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </template>

  </Layout>
</template>